import { downgradeProbabilityConstants } from '../constants/downgrade-probability';
import { ITimeseries, IDowngradeBucket } from '../types/company';
import ICompany from '../types/company';
import moment from 'moment';

// TODO: ERP-1247: phase out this code -- should only be done in API
export const getDowngradeBucket = (probability: number): IDowngradeBucket => {
  let text = '';
  let color = '';
  let textColor = '';
  let sortOrder = 0;

  for (const item of downgradeProbabilityConstants) {
    if (probability >= item.range.low && probability < item.range.high) {
      text = item.text;
      color = item.color;
      textColor = item.textColor;
      sortOrder = item.sortOrder;
    }
  }

  return {
    text,
    color,
    textColor,
    probability,
    sortOrder,
  };
};

export const getDowngradeProbability = (company: ICompany | undefined) => {
  if (!company) return 0;
  const timeSeries = company.time_series;
  return timeSeries[timeSeries.length - 1]?.score || 0;
};

export const ARTICLE_THRESHOLD: number = 25;
