import { format, startOfMonth, startOfYear, subDays, addDays } from 'date-fns';
import Highcharts, { Series } from 'highcharts';
import { getClosestBeginningOfIntervalDate, fixedNewDate } from '../utils/dates';
import '../styles/CompanyGraphs.scss';
import { ExtendedAxis } from '../extensions/axis';
import { IntervalLengthConstants } from '../constants/interval-length-constants';
import { IDataFromTimerange } from '../types/company-chart';
import { negativeArticlesId, positiveArticlesId, downgradeScoreId, estimatedScoreId } from '../constants/company-chart';
import { SeriesData } from './series-data';

const numberOfSeries = 4;

export const createArticleOverrideDateRange = (endDate: string, previousEndDate: string) => {
  // Set end date to the day before the selected as the score data comes from the day before
  // Set start date to 182 days before the start date of the selected range to match the pipeline's article window
  const dateFormat = 'yyyy-MM-dd';
  const startDateOfRange = format(addDays(new Date(previousEndDate), 1), dateFormat);
  const dayBeforeEndDate = format(subDays(new Date(endDate), 1), dateFormat);
  const startDate = format(subDays(new Date(startDateOfRange), 182), dateFormat);
  return `${startDate} - ${dayBeforeEndDate}`;
};

const isNotDaily = (interval: number): boolean => {
  return interval > IntervalLengthConstants.Day;
};

const isNotMonthly = (interval: number): boolean => {
  return interval > IntervalLengthConstants.Month;
};

export const readjustLabels = (chart: Highcharts.Chart) => {
  if (!chart) {
    return;
  }
  const axis = chart.xAxis[0] as ExtendedAxis;
  const ticks = axis.tickPositions!;
  const width = axis.width / ticks.length;
  const newX = width / 2;

  const monthDifference = SeriesData.calculateDifferenceInMonths(axis.categories[0], axis.categories.at(-1)!);
  const interval = SeriesData.getIntervalLength(monthDifference);
  const centreLabel = isNotMonthly(interval);
  axis.update({
    tickLength: centreLabel ? 30 : 10,
    labels: {
      x: centreLabel ? newX : undefined,
    },
  });
};

export const repositionTicks = (axis: Highcharts.Axis, data: IDataFromTimerange): Highcharts.AxisTickPositionsArray => {
  let positions: Highcharts.AxisTickPositionsArray = [];
  const extendedAxis = axis as ExtendedAxis;
  let lastTick = extendedAxis.dataMax;
  let firstTick = extendedAxis.dataMin;
  const increment = data.xAxisTickInterval;
  let tick: number | undefined;
  for (; lastTick >= firstTick; lastTick -= increment) {
    tick = lastTick;
    if (isNotDaily(data.daysInOneTick)) {
      tick = getClosestTickToBeginningOfInterval(lastTick, axis, data.daysInOneTick, positions.at(-1));
    }

    if (tick) {
      lastTick = tick;
      positions.push(lastTick);
    }
  }

  // Note: only the week column size was overlapping, that's why it's excluded here
  if (positions.findIndex((pos) => pos === 0) === -1) {
    if (positions.length === 1 || Math.abs(0 - positions.at(-1)!) > Math.abs(positions.at(-1)! - positions.at(-2)!) / 2) {
      positions.push(0);
    }
  }

  return positions.reverse();
};

const getClosestTickToBeginningOfInterval = (tick: number, axis: Highcharts.Axis, interval: number, lastTick: number | undefined) => {
  let lastDate = axis.categories[tick];
  if (lastDate === undefined) {
    return tick;
  }
  let closestBeginningOfIntervalDate = fixedNewDate(lastDate);
  let lastIntervalDate = !lastTick ? undefined : fixedNewDate(axis.categories[axis.categories.length - 1]);
  switch (interval) {
    case IntervalLengthConstants.Month:
      if (
        lastIntervalDate &&
        lastIntervalDate.getMonth() === closestBeginningOfIntervalDate.getMonth() &&
        lastIntervalDate.getFullYear() === closestBeginningOfIntervalDate.getFullYear()
      ) {
        return undefined;
      }
      closestBeginningOfIntervalDate = getClosestBeginningOfIntervalDate(lastDate, axis.categories, startOfMonth);
      break;
    case IntervalLengthConstants.Year:
      if (lastIntervalDate && lastIntervalDate.getFullYear() === closestBeginningOfIntervalDate.getFullYear()) {
        return undefined;
      }
      closestBeginningOfIntervalDate = getClosestBeginningOfIntervalDate(lastDate, axis.categories, startOfYear);
      break;
  }

  if (Number.isNaN(+closestBeginningOfIntervalDate)) {
    return undefined;
  }

  const lastCategory = format(closestBeginningOfIntervalDate, 'yyyy-MM-dd');
  const categoryIndex = axis.categories.indexOf(lastCategory);
  return categoryIndex;
};

export const getSeriesValues = (chart: any, series: Series[] | undefined, index: number) => {
  let downgradeValue = 0;
  let isEstimated = false;
  let positiveArticlesValue = 0;
  let negativeArticlesValue = 0;

  if (series && series.length >= numberOfSeries) {
    const rawDowngradeValue = (chart?.get(downgradeScoreId) as Highcharts.Series)?.data[index]?.y;
    if (rawDowngradeValue) {
      downgradeValue = Math.round(rawDowngradeValue * 100);
    }
    if (downgradeValue === 0) {
      const estimatedDowngradeValue = (chart?.get(estimatedScoreId) as Highcharts.Series)?.data[index]?.y;
      if (estimatedDowngradeValue) {
        downgradeValue = Math.round(estimatedDowngradeValue * 100);
        isEstimated = true;
      }
    }
    const rawPositiveArticlesValue = (chart?.get(positiveArticlesId) as Highcharts.Series)?.data[index]?.y;
    if (rawPositiveArticlesValue) {
      positiveArticlesValue = Math.abs(rawPositiveArticlesValue);
    }
    const rawNegativeArticlesValue = (chart?.get(negativeArticlesId) as Highcharts.Series)?.data[index]?.y;
    if (rawNegativeArticlesValue) {
      negativeArticlesValue = Math.abs(rawNegativeArticlesValue);
    }
  }

  return { downgradeValue, isEstimated, positiveArticlesValue, negativeArticlesValue };
};
