import { useEffect } from 'react';
import Container from '../components/Container';
import DowngradeScale from '../components/DowngradeScale';
import useCurrentPage from '../hooks/use-set-current-page';
import { NavLink } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import aboutCreateLists from './about-create-lists.png';
import subPortfolios from './sub-portfolios.png';
import accessLists from './access-lists.png';

export default function About() {
  const currentPage = useCurrentPage();

  useEffect(() => {
    currentPage.setCurrentPage('About');
  }, []);

  return (
    <div className="mb-auto">
      <PageHeader pageTitle="How This Works" />
      <Container>
        <div className="lg:flex lg:justify-center lg:w-full">
          <div className="lg:w-full lg:bg-white lg:p-8">
            <h2 className="font-mmc text-xl mb-2">How the Score Works</h2>
            <p className="text-xs mb-4">
              Our Natural Language Models transform words in to scores to directly predict a credit event by using the latest transformer
              models and Oliver Wyman's 30+ years experience in credit quant, which helps you keep track of important changes that you don't
              want to miss. The FSS tool is trained to use an aggregate view of news articles to predict adverse credit events. For
              companies where less 25 news articles have been published within the most recent 6-month period, the FSS score may be volatile
              (presented by a dashed line on the chart).
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">FSS Score</h2>
            <p className="text-xs mb-4">
              The scale below shows how the FSS Score ranges translate to easy to understand traffic light signals throughout the
              application. The categories give indicative view of the level of risk detected within the news. Its important to remember that
              typical FSS Score ranges may differ by industry (eg. Petrochemical companies versus eCommerce) or region (eg. USA versus
              Sierra Leone), so whilst some companies may regularly be indicated as "High" others may never get out of the "Very Low" range.
              It is therefore always important to consider the percentage change in FSS Scores for a given entity over time, as well as the
              absolute FSS score.
            </p>

            <div className="max-w-xl mb-4">
              <DowngradeScale />
            </div>

            <p className="text-xs mb-4">
              The likelihood of a company experiencing an adverse credit event when their FSS score is in the ‘Very Low’ category is
              minimal; however, in extreme examples this can happen. For example, testing using the Russell 2000 index showed that c.5% of
              companies with a Very Low score over 2023 experienced a downgrade in that period time. Conversely, c.50% of companies with a
              Very High score were downgraded in 2023
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">Create lists to help manage your Portfolio</h2>

            <p className="text-xs mb-4">Companies that you have added to your main Portfolio can be also added to custom lists.</p>

            <p className="text-xs mb-4">
              Create a new list from the <a href="/">Portfolio page</a> by using the dropdown option "<b>Add / remove list</b>" in the
              column of the same name, next to the Company you wish to add. Click on "<b>+ Create new list</b>" and you can give the new
              list any name you choose.
            </p>
            <p className="text-xs mb-4">
              <img src={aboutCreateLists} alt="Screenshot of creating lists" />
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">Access Lists</h2>
            <p className="text-xs mb-4">
              The lists you create will now be accessible from the <a href="/lists">Lists</a> link next to "Companies".
            </p>
            <p className="text-xs mb-4">
              <img src={accessLists} alt="Screenshot of accessing lists" />
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">Visualise your sub-portfolios from your List</h2>
            <p className="text-xs mb-4">
              Once in your <b>Lists</b> section, click on any list and you will be able to see your list as a <b>snapshot</b>, or over{' '}
              <b>time</b>.
            </p>
            <p className="text-xs mb-4">
              <img src={subPortfolios} alt="Screenshot of visualising sub-portfolios" />
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">Customise your email preferences</h2>
            <p className="text-xs mb-4">
              Change the frequency of email notifications you receive and customise whether you receive "low article" scores by editing your
              preferences on the <a href="/settings">Settings</a> page.
            </p>

            <h2 className="font-mmc text-xl mb-2 mt-6">Need more help?</h2>
            <p className="text-xs mb-4">
              If you get stuck at any point and would like some assistance please{' '}
              <NavLink to="/contact" className="text-ow-secondary">
                let us know.
              </NavLink>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
