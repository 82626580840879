import { breakPoints, downgradeProbabilityConstants } from '../constants/downgrade-probability';
import formatPercentage from '../utils/format-percentage';

export default function DowngradeScale() {
  const items = downgradeProbabilityConstants;

  const formatRangeValue = (range: any) => {
    // display start of range for very low as 0
    const low = range.low * 100 === breakPoints[0] ? 0 : range.low;
    return low < range.high
      ? `${formatPercentage(low, true)} to ${range.high === 100 ? '' : '<'}${formatPercentage(range.high, true)}`
      : formatPercentage(low, true);
  };

  const getText = (item: any) => {
    if (item.key) {
      return item.key;
    }

    return `${item.text} indication of FSS Score`;
  };

  return (
    <div>
      {items.map((item) => (
        <div key={item.text} className={`bg-${item.color} p-3 rounded text-${item.textColor} text-lg mt-4`}>
          <div className="flex justify-between font-mmc mb-1">
            <p>{item.text}</p>
            {/* for Low Articles, so no range */}
            {item.range.low > 0 && <p>{formatRangeValue(item.range)}</p>}
          </div>
          <p className="text-sm">{getText(item)}</p>
        </div>
      ))}
    </div>
  );
}
