import { NavLink } from 'react-router-dom';
import { IHeaderInfo } from '../types/company';
import BackButton from './BackButton';
import { ReactNode } from 'react';

export default function PageHeader({
  headerInfo,
  pageTitle,
  isList = false,
  children,
}: {
  headerInfo?: IHeaderInfo | undefined;
  pageTitle?: string;
  isList?: boolean;
  children?: ReactNode;
}) {
  return (
    <div className="sticky z-10">
      <div className="text-white bg-ow-darker-grey border-t border-white">
        <div className="container mx-auto px-6 py-3">
          <div className="lg:px-8 text-xs 2xl:text-sm uppercase font-medium">
            <div className="flex justify-between">
              <div className="flex">
                <BackButton />
              </div>
              {pageTitle && (
                <div className="flex items-center">
                  <div className="text-lg md:text-2xl font-mmc">{pageTitle}</div>
                </div>
              )}
              {headerInfo && (
                <div className="flex items-center">
                  <div className="font-mmc max-w-[380px] sm:max-w-[500px] md:max-w-full lg:max-w-[350px] xl:max-w-[550px] 2xl:max-w-full">
                    <span className="text-lg md:text-xl 2xl:text-2xl">{headerInfo.name}</span>
                    {isList && <span className="text-sm md:text-lg ml-1.5 pb-[1px]">({headerInfo.numCompanies})</span>}
                    {!isList && headerInfo.country && (
                      <span className="text-lg md:text-xl 2xl:text-2xl font-mmcrg">, {headerInfo.country}</span>
                    )}
                  </div>
                  <div className="hidden lg:block mx-3 h-[16px] w-[1px] bg-ow-dark-grey"></div>
                  <div className="hidden lg:flex items-center">
                    <div>{isList && 'Avg. '}Score</div>
                    <div className="font-bold ml-2">{headerInfo.score}</div>
                  </div>
                  <div className="hidden lg:block mx-3 h-[16px] w-[1px] bg-ow-dark-grey"></div>
                  <div className="hidden lg:flex items-center">
                    <div>{isList && 'Avg. '}Change</div>
                    <div className="font-bold ml-2">{headerInfo.change}</div>
                  </div>
                  <div className="hidden lg:block mx-3 h-[16px] w-[1px] bg-ow-dark-grey"></div>
                  <div className="hidden lg:flex items-center">
                    <div>{isList && 'Avg. '}Signal</div>
                    <NavLink to="/about" className={`hover:no-underline`}>
                      <span
                        className={`text-xs px-3 min-w-[5.5rem] ml-2 normal-case font-normal text-center py-1 rounded-full bg-${headerInfo.signal.color} text-${headerInfo.signal.textColor}`}
                      >
                        {headerInfo.signal.text}
                      </span>
                    </NavLink>
                  </div>
                </div>
              )}
              {!children && (
                <div className="flex opacity-0 min-h-[32px]">
                  <BackButton />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
